/* eslint-disable class-methods-use-this */
import { CasinoRouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export class CasinoBetgamesModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    const navigation = process.env.VUE_APP_LAYOUT_PHONE
      ? () => import('../navigation/views/CasinoNavigationRouteComponent/CasinoNavigationRouteComponent.vue')
      : undefined;

    router.addDefaultRoute({
      name: CasinoRouteName.CASINO_BETGAMES,
      path: '/betgames',
      prefetch: () => import('./prefetch/CasinoBetgamesPagePrefetch'),
      // eslint-disable-next-line max-len
      component: () => import('./pages/CasinoBetgamesRoutePage/CasinoBetgamesRoutePage.vue'),
      navigation,
    });
  }
}
