import type {
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
  RouteParamValue,
  Router,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router';
import type { AsyncComponentLoader } from 'vue';

import { QueryParameterName } from '@leon-hub/query-manager';
import { RouteName } from '@leon-hub/routing-config-names';
import {
  assert,
  isObject,
  isString,
} from '@leon-hub/guards';
import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { Json, Timer } from '@leon-hub/utils';
import type { Theme } from '@leon-hub/api-sdk';

import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import type { RoutingSeoData } from 'web/src/modules/core/types';
import {
  useDesktopModalStore,
  useRouterStore,
} from 'web/src/modules/core/store';
import { importComponent, createGetHref } from 'web/src/modules/core/utils';
import type { ModalHeaderType } from 'web/src/components/Modal/ModalWindowHeader/types';
import type { DesktopModalCloseEvent } from 'web/src/modules/core/store/types/desktopModalStore';

import { isSameRoute } from './utils';
import processAfterRoute from './navigation-guards/history-state/processAfterRoute';
import type {
  AppVueRouter,
  BaseRouteConfig,
  BlankRouteConfig,
  DefaultRouteConfig,
  ModalRouteConfig,
  PrefetchRouteConfig,
  BaseTopBarRouteConfig,
  PushOptions,
} from './types';

declare module 'vue-router' {
  export interface TypesConfig {
    $router: AppVueRouter;
  }

  export interface RouteMeta {
    transition?: string;
    transitionTo?: string;
    transitionFrom?: string;
    isFooterHidden?: boolean;
    hasFixedBar?: boolean;
    isEgsLobbyPage?: boolean;
    isBannerHidden?: boolean;
    doSaveScrollPosition?: boolean;
    topBarType?: ModalHeaderType;
    theme?: Theme;
  }

  export function useRouter(): AppVueRouter;
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $route: RouteLocationNormalizedLoaded;
    $router: AppVueRouter;
  }
}

let customSeoConfigs: Record<string, RoutingSeoData> = {};
let isRouteReplace = false;
let isModalReplace = false;
let doSaveScrollPosition = false;

function replace(router: Router): AppVueRouter['replace'] {
  return (location) => {
    isRouteReplace = true;
    return router.replace(location);
  };
}

function canNavigateTo(router: Router): AppVueRouter['canNavigateTo'] {
  return (location) => {
    const current = router.currentRoute;
    const target = router.resolve(location);

    return !isSameRoute(target, current.value);
  };
}

function isDuplicatedRouteWithModal(router: Router, location: RouteLocationRaw): boolean {
  if (process.env.VUE_APP_LAYOUT_DESKTOP && useDesktopModalStore().desktopModal) {
    const resolvedLocation = router.resolve(location);
    if (resolvedLocation.fullPath === router.currentRoute.value.fullPath) {
      return true;
    }
  }

  return false;
}

function resolveRedirectLocation(router: Router): AppVueRouter['resolveRedirectLocation'] {
  return (location, code) => {
    if (process.env.VUE_APP_PRERENDER) {
      let query: Record<string, string> = {};
      if (typeof location === 'string') {
        const url = new URL(location);
        for (const [key, value] of url.searchParams.entries()) {
          query[key] = value;
        }
      } else {
        query = (location.query as Record<string, string> | undefined) ?? {};
      }

      for (const key of [
        QueryParameterName.PRERENDER,
        QueryParameterName.PRERENDER_HOST,
        QueryParameterName.PRERENDER_IP,
      ]) {
        if (query[key]) {
          delete query[key];
        }
      }
      const redirectLocation = router.resolve({
        ...router.resolve(location),
        query,
      });

      return {
        name: RouteName.REDIRECT_SEO,
        state: {
          redirectLocation: redirectLocation.href,
          redirectCode: code,
        },
      };
    }

    return location;
  };
}

function resolve301Location(router: Router): AppVueRouter['resolve301location'] {
  return (location) => resolveRedirectLocation(router)(location, '301');
}

function resolve302Location(router: Router): AppVueRouter['resolve301location'] {
  return (location) => resolveRedirectLocation(router)(location, '302');
}

function isOpenedByPush(router: Router): AppVueRouter['isOpenedByPush'] {
  return () => !!router.currentRoute.value.query[QueryParameterName.FROM_PUSH];
}

function removePushQuery(router: Router): AppVueRouter['removePushQuery'] {
  return () => {
    const { query, name, params } = router.currentRoute.value;

    if (query?.[QueryParameterName.FROM_PUSH]) {
      delete query[QueryParameterName.FROM_PUSH];
    }

    void replace(router)({
      name: name || '',
      params,
      query,
    });
  };
}

function getPath(name: RouteRecordName, path: string): string {
  assert(isString(name));
  return customSeoConfigs[name]?.path || path;
}

function getImportComponent(
  component: AsyncComponentLoader,
) {
  return () => importComponent(
    component,
  );
}

function getRouteBaseConfig(config: BaseRouteConfig): Pick<RouteRecordRaw, 'name' | 'path' | 'meta'> {
  return {
    name: config.name,
    path: getPath(config.name, config.path),
    meta: {
      ...(config.meta ?? {}),
      access: config.access,
      prefetch: config.prefetch,
      seoController: config.seoController,
    },
  };
}

function getBlankRouteConfig(
  config: BlankRouteConfig,
): RouteRecordRaw {
  const baseConfig = getRouteBaseConfig(config);

  return {
    ...baseConfig,
    component: getImportComponent(config.component),
    props: config.props,
  };
}

function getPrefetchComponent(
  config: PrefetchRouteConfig,
): RouteRecordRaw {
  return getBlankRouteConfig({
    ...config,
    component: () => import('web/src/modules/errors/pages/RedirectSeoRouteComponent/RedirectSeoRouteComponent.vue'),
  });
}

function addDefaultDesktopRoute(
  router: Router,
  config: DefaultRouteConfig,
): void {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error('Only for DESKTOP Layout');
  }

  const baseConfig = getRouteBaseConfig(config);

  router.addRoute({
    ...baseConfig,
    meta: {
      ...baseConfig.meta,
      ...(config.leftSideBar ? { hasLeftSidebar: true } : {}),
      ...(config.fixedBar ? { hasFixedBar: true } : {}),
      ...(config.isFooterHidden ? { isFooterHidden: true } : {}),
      ...(config.isRightSidebarHidden ? { isRightSidebarHidden: true } : {}),
      ...(config.isLeftSidebarToggleHidden ? { isLeftSidebarToggleHidden: true } : {}),
      ...(config.theme ? { theme: config.theme } : {}),
    },
    components: {
      default: getImportComponent(config.component),
      ...(config.fixedBar ? {
        fixedBar: getImportComponent(config.fixedBar),
      } : {}),
      ...(config.navigation ? {
        navigation: getImportComponent(config.navigation),
      } : {}),
      ...(config.leftSideBar ? {
        leftSideBar: getImportComponent(config.leftSideBar),
      } : {}),
      ...(config.title ? {
        title: getImportComponent(
          () => import('web/src/modules/core/components/DesktopTitle/DesktopTitle.vue'),
        ),
      } : {}),
      ...(config.contentLoader ? {
        contentLoader: getImportComponent(config.contentLoader),
      } : {}),
      ...(config.topAuthorizedSlot ? {
        topAuthorizedSlot: getImportComponent(config.topAuthorizedSlot),
      } : {}),
    },
    props: {
      default: config.props ?? {},
      ...(config.title ? {
        title: {
          title: config.title,
          isBig: config.isBigTitle,
        },
      } : {}),
    },
  });
}

function addPhoneDefaultRoute(
  router: Router,
  config: DefaultRouteConfig,
): void {
  if (!process.env.VUE_APP_LAYOUT_PHONE) {
    throw new Error(`Only for PHONE Layout. Config=${Json.stringify(config)}`);
  }

  if (config.leftSideBar) {
    throw new Error(`Navigation allowed only for DESKTOP layout. Config=${Json.stringify(config)}`);
  }

  if (config.title) {
    throw new Error(`Title allowed only for DESKTOP layout. Config=${Json.stringify(config)}`);
  }

  const baseConfig = getRouteBaseConfig(config);

  router.addRoute({
    ...baseConfig,
    components: {
      default: getImportComponent(config.component),
      ...(config.fixedBar ? {
        fixedBar: getImportComponent(config.fixedBar),
      } : {}),
      ...(config.navigation ? {
        navigation: getImportComponent(config.navigation),
      } : {}),
      ...(config.contentLoader ? {
        contentLoader: getImportComponent(config.contentLoader),
      } : {}),
      ...(config.topAuthorizedSlot ? {
        topAuthorizedSlot: getImportComponent(config.topAuthorizedSlot),
      } : {}),
    },
    meta: {
      ...baseConfig.meta,
      ...(config.isFooterHidden ? { isFooterHidden: true } : {}),
      ...(config.fixedBar ? { hasFixedBar: true } : {}),
      ...(config.theme ? { theme: config.theme } : {}),
    },
    props: {
      default: config.props ?? {},
    },
  });
}

function addDefaultRoute(
  router: Router,
) {
  return (config: DefaultRouteConfig) => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      addDefaultDesktopRoute(router, config);
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      addPhoneDefaultRoute(router, config);
    }
  };
}

function getBaseDesktopModalConfig(config: ModalRouteConfig): RouteRecordRaw {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error(`Only for DESKTOP layout. Config=${Json.stringify(config)}`);
  }

  if (!config.modalPreset) {
    throw new Error(`Modal preset not provided. Config=${Json.stringify(config)}`);
  }

  const baseConfig = getRouteBaseConfig(config);

  return {
    ...baseConfig,
    meta: {
      ...baseConfig.meta,
      ...(config.theme ? { theme: config.theme } : {}),
      hasTopBar: true,
      modalPreset: config.modalPreset,
      desktopModal: {
        isNoMinHeightLimit: config.isNoMinHeightLimit,
        default: {
          component: config.component,
          props: config.props,
        },
        navigation: config.navigation ? {
          component: config.navigation,
        } : undefined,
        topBar: {
          component:
            config.topBar
            ?? (() => import('web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue')),
          props: {
            ...(config.title ? { title: config.title } : {}),
            ...(config.topBarType ? { type: config.topBarType } : {}),
            ...(config.topBarProps ?? {}),
          },
        },
      },
    },
    component: VEmpty,
  };
}

function getProfileDesktopModalRouteConfig(config: BaseTopBarRouteConfig): RouteRecordRaw {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error('Only for DESKTOP layout');
  }

  const baseConfig = getBaseDesktopModalConfig({
    ...config,
    modalPreset: ModalDesktopPreset.ProfileDesktopModal,
  });
  assert(baseConfig.meta);
  assert(isObject(baseConfig.meta.desktopModal));
  baseConfig.meta.desktopModal.isProfile = true;

  return baseConfig;
}

function getPhoneTopBarRouteConfig(
  config: BaseTopBarRouteConfig,
): RouteRecordRaw {
  if (!process.env.VUE_APP_LAYOUT_PHONE) {
    throw new Error('Only for PHONE layout');
  }

  const baseConfig = getRouteBaseConfig(config);

  return {
    ...baseConfig,
    meta: {
      ...baseConfig.meta,
      hasTopBar: true,
      ...(config.isTabBarVisible ? { isTabBarVisible: true } : {}),
      ...(config.isFooterVisible ? { isFooterVisible: true } : {}),
      ...(config.topBarType ? { topBarType: config.topBarType } : {}),
    },
    components: {
      default: getImportComponent(config.component),
      topBar: getImportComponent(
        // eslint-disable-next-line max-len
        config.topBar ?? (() => import('web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue')),
      ),
      ...(config.navigation ? {
        navigation: getImportComponent(
          config.navigation,
        ),
      } : {}),
    },
    props: {
      default: config.props ?? {},
      topBar: {
        ...(config.title ? { title: config.title } : {}),
        ...(config.topBarType ? { type: config.topBarType } : {}),
        ...(config.topBarProps ?? {}),
      },
    },
  };
}

function addProfileRoute(
  router: Router,
  isAnonymous = false,
) {
  return (config: BaseTopBarRouteConfig) => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      router.addRoute(getProfileDesktopModalRouteConfig(isAnonymous ? config : {
        ...config,
        access: CustomerRouteAccessRole.AUTHORIZED,
      }));
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.addRoute(getPhoneTopBarRouteConfig(isAnonymous ? config : {
        ...config,
        access: CustomerRouteAccessRole.AUTHORIZED,
      }));
    }
  };
}

function addModalRoute(
  router: Router,
) {
  return (config: ModalRouteConfig) => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      router.addRoute(getBaseDesktopModalConfig(config));
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.addRoute(getPhoneTopBarRouteConfig(config));
    }
  };
}

function resolveByPopState(resolve: () => void): void {
  let timer = 0;

  const popstateListener = () => {
    window.removeEventListener('popstate', popstateListener);
    if (timer) {
      Timer.clearTimeout(timer);
      timer = 0;
    }
    resolve();
  };

  window.addEventListener('popstate', popstateListener);
  timer = Timer.setTimeout(popstateListener, 5000);
}

async function preventCloseModalEvents(): Promise<boolean> {
  let isPrevented = false;
  const closeEvent: DesktopModalCloseEvent = {
    preventDefault: () => { isPrevented = true; },
  };
  const desktopStore = useDesktopModalStore();

  if (desktopStore.closeModalEvents.length) {
    await Promise.all(desktopStore.closeModalEvents.map((callback) => callback(closeEvent)));
  }

  return isPrevented;
}

function closeModalInner(router: AppVueRouter, offset: number): Promise<void> {
  const desktopStore = useDesktopModalStore();

  return new Promise<void>((resolve) => {
    const store = useRouterStore();
    const { getModalRouteHistoryDelta } = store;
    const delta = getModalRouteHistoryDelta() + offset;
    if (process.env.VUE_APP_LAYOUT_DESKTOP && delta === 0 && desktopStore.desktopModal) {
      desktopStore.setDesktopModal(undefined);
      const current = router.currentRoute.value.fullPath;

      window.history.replaceState({
        ...window.history.state,
        current,
        meta: undefined,
        prevMeta: undefined,
      }, '', router.resolve(current).href);
      processAfterRoute(router.currentRoute.value, router.currentRoute.value, router);
      resolve();
      return;
    }

    if (delta >= 0) {
      resolve();
      return;
    }

    resolveByPopState(resolve);

    router.go(delta);
  });
}

function closeModal(router: AppVueRouter) {
  return async (offset = 0) => {
    if (await preventCloseModalEvents()) {
      return;
    }

    await closeModalInner(router, offset);
  };
}

export default function getAppVueRouter(
  router: Router,
): AppVueRouter {
  // @ts-ignore
  return {
    ...router,
    setSeoConfigs(configs: Record<string, RoutingSeoData>): void {
      customSeoConfigs = configs;
    },
    getPrevHistoryUrl(): string | undefined {
      return useRouterStore().previousRouteUrl;
    },
    isPopStateDetected(): boolean {
      return useRouterStore().isPopStateDetected;
    },
    getHref: createGetHref(router),
    canNavigateTo: canNavigateTo(router),
    resolveRedirectLocation: resolveRedirectLocation(router),
    resolve301location: resolve301Location(router),
    resolve302location: resolve302Location(router),
    pushRedirect(location, code, options) {
      const redirectLocation = code === 301
        ? this.resolve301location(location)
        : this.resolve302location(location);
      return this.push(redirectLocation, options);
    },
    push301(location, options) {
      return this.pushRedirect(location, 301, options);
    },
    push302(location, options) {
      return this.pushRedirect(location, 302, options);
    },
    replace(location) {
      if (isDuplicatedRouteWithModal(router, location)) {
        return this.closeModal();
      }

      return replace(router)(location);
    },
    getParam<T extends RouteParamValue | RouteParamValue[] = string>(param: string): T | undefined {
      if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
        return router.currentRoute.value.params[param] as T;
      }

      const { desktopModal } = useDesktopModalStore();

      return desktopModal ? desktopModal.route.params[param] as T : router.currentRoute.value.params[param] as T;
    },
    getQuery(param) {
      if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
        return router.currentRoute.value.query[param];
      }

      const { desktopModal } = useDesktopModalStore();
      return desktopModal ? desktopModal.route.query[param] : router.currentRoute.value.query[param];
    },
    getName() {
      if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
        return router.currentRoute.value.name;
      }

      const { desktopModal } = useDesktopModalStore();

      return desktopModal ? desktopModal.route.name : router.currentRoute.value.name;
    },
    isReplaced() {
      return isRouteReplace;
    },
    processAfterEach() {
      isRouteReplace = false;
      isModalReplace = false;
      doSaveScrollPosition = false;
      useRouterStore().resetPopState();
    },
    isModalReplaced() {
      return isModalReplace;
    },
    doSaveScrollPosition() {
      return doSaveScrollPosition;
    },
    push(location, options?: PushOptions) {
      if (options?.cancelOnPopstate && this.isPopStateDetected()) {
        return Promise.resolve(undefined);
      }

      if (options?.saveScrollPosition) {
        doSaveScrollPosition = true;
      }

      if (isDuplicatedRouteWithModal(router, location)) {
        return this.closeModal();
      }

      return router.push(location);
    },
    isOpenedByPush: isOpenedByPush(router),
    removePushQuery: removePushQuery(router),
    back(defaultLocation?: RouteLocationRaw) {
      if (!window.history.state?.back) {
        void replace(router)(defaultLocation || {
          name: RouteName.HOME,
        });
      } else {
        router.back();
      }
    },
    backAsync(defaultLocation?: RouteLocationRaw) {
      if (!window.history.state?.back) {
        void replace(router)(defaultLocation || {
          name: RouteName.HOME,
        });
        return Promise.resolve();
      }

      return new Promise<void>((resolve) => {
        resolveByPopState(resolve);
        router.back();
      });
    },
    forceBack(defaultLocation?: RouteLocationRaw): void {
      if (!window.history.state) {
        this.go(-2);
        return;
      }

      const historyLengthDiff = window.history.length - (window.history.state.historyLength || 0);
      if (window.history.state?.back && historyLengthDiff > 0) {
        this.go(-(1 + historyLengthDiff));
      } else {
        this.back(defaultLocation);
      }
    },
    async replaceModal(route) {
      isModalReplace = true;
      await closeModal(this)(1);
      void replace(router)(route);
    },
    closeModal() {
      return closeModal(this)(0);
    },
    addBlankRoute(route) {
      const config = getBlankRouteConfig(route);

      router.addRoute({
        ...config,
        meta: {
          ...(config.meta ?? {}),
          isBlank: true,
        },
      });
      return this;
    },
    addPrefetchRoute(route) {
      router.addRoute(getPrefetchComponent(route));
      return this;
    },
    addLandingRoute(route) {
      const config = getBlankRouteConfig(route);

      router.addRoute({
        ...config,
        meta: {
          ...(config.meta ?? {}),
          isLanding: true,
        },
      });
      return this;
    },
    addDefaultRoute(route) {
      addDefaultRoute(router)(route);
      return this;
    },
    addModalRoute(route) {
      addModalRoute(router)(route);
      return this;
    },
    addProfileRoute(route) {
      addProfileRoute(router)(route);
      return this;
    },
    addAnonymousProfileRoute(route) {
      addProfileRoute(router, true)(route);
      return this;
    },
    next(originalNext, nextTo?: RouteLocationRaw) {
      if (nextTo) {
        if (isObject(nextTo) && nextTo.replace) {
          isRouteReplace = true;
        }

        originalNext(nextTo);

        if (isObject(nextTo) && nextTo.state) {
          window.history.replaceState({
            ...window.history.state,
            ...nextTo.state,
          }, '');
        }
      } else {
        originalNext();
      }
    },
  };
}
