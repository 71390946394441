import { CustomerRouteAccessRole } from '@leon-hub/routing-config';

export default function isRouteAllowed(
  allowAccessFor?: CustomerRouteAccessRole,
  isUserLoggedIn?: boolean,
): boolean {
  if (!allowAccessFor) {
    return true;
  }

  if (allowAccessFor === CustomerRouteAccessRole.FORBIDDEN) {
    return false;
  }

  const currentUserRole = isUserLoggedIn
    ? CustomerRouteAccessRole.AUTHORIZED
    : CustomerRouteAccessRole.ANONYMOUS;

  return currentUserRole === allowAccessFor;
}
