import { RouteName } from '@leon-hub/routing-config-names';
import { CustomerRouteAccessRole } from '@leon-hub/routing-config';

import { RegistrationStage } from 'web/src/modules/registration/enums';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter, DefaultRouteConfig, ModalRouteConfig } from 'web/src/modules/core/services/router/types';

interface RegistrationRouteConfig {
  name: DefaultRouteConfig['name'];
  path: DefaultRouteConfig['path'];
  access: DefaultRouteConfig['access'];
  stage: RegistrationStage;
  title?: string;
}

function createRegistrationRoute(router: AppVueRouter, {
  name,
  path,
  stage,
  title,
  access,
  ...config
}: RegistrationRouteConfig & Partial<Exclude<ModalRouteConfig, keyof RegistrationRouteConfig >>): void {
  router.addModalRoute({
    ...config,
    name,
    path,
    access,
    component: () => import('web/src/modules/registration/submodules/simplified-registration/views/SimpleRegistrationRouteComponent.vue'),
    props: {
      stage,
    },
    modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
    topBar: () => import('web/src/modules/registration/submodules/simplified-registration/views/SimpleRegistrationTopBarRouteComponent.vue'),
    title,
    meta: {
      ...config.meta,
      saveRouteOnLogin: true,
    },
  });
}

export class SimplifiedRegistrationModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    createRegistrationRoute(router, {
      name: RouteName.REGISTRATION,
      path: '/registration',
      stage: RegistrationStage.Main,
      access: CustomerRouteAccessRole.ANONYMOUS,
      title: '{{$t(\'WEB2_REGISTRATION_NAV_TITLE\')}}',
      prefetch: process.env.VUE_APP_FEATURE_REGISTRATION_PREFETCH_ENABLED
        ? () => import('web/src/modules/registration/prefetch/RegistrationPrefetch')
        : undefined,
    });

    createRegistrationRoute(router, {
      name: RouteName.SIMPLE_REGISTRATION_PHONE_CHECK,
      path: '/registration/phone-check',
      stage: RegistrationStage.PhoneCheck,
      title: '{{$t(\'WEB2_REGISTRATION_PHONE_CHECK_NAV_TITLE\')}}',
      access: CustomerRouteAccessRole.ANONYMOUS,
    });

    createRegistrationRoute(router, {
      name: RouteName.SIMPLE_REGISTRATION_CHANGE_PASSWORD,
      path: '/registration/change-password',
      stage: RegistrationStage.ChangePassword,
      access: CustomerRouteAccessRole.AUTHORIZED,
    });

    createRegistrationRoute(router, {
      name: RouteName.SIMPLE_REGISTRATION_CHANGE_PASSWORD_SUCCESS,
      path: '/registration/change-password/success',
      stage: RegistrationStage.ChangePasswordSuccess,
      access: CustomerRouteAccessRole.AUTHORIZED,
    });

    createRegistrationRoute(router, {
      name: RouteName.SIMPLE_REGISTRATION_CHANGE_PHONE,
      path: '/registration/change-phone',
      stage: RegistrationStage.ChangePhone,
      access: CustomerRouteAccessRole.ANONYMOUS,
    });

    createRegistrationRoute(router, {
      name: RouteName.SIMPLE_REGISTRATION_SUCCESS,
      path: '/registration/success',
      stage: RegistrationStage.Success,
      access: CustomerRouteAccessRole.AUTHORIZED,
    });
  }
}
