/* eslint-disable class-methods-use-this */
import { CasinoRouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter, ModalRouteConfig } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

function getBaseCasinoGroupsRoute({ name, path }: Pick<ModalRouteConfig, 'name' | 'path'>): ModalRouteConfig {
  return {
    name,
    path,
    modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.ProviderSlotDesktopModal : undefined,
    topBar: () => import('web/src/modules/casino/submodules/groups/views/CasinoGroupsTopBarRouteComponent/CasinoGroupsTopBarRouteComponent.vue'),
    component: () => import('web/src/modules/casino/submodules/groups/pages/CasinoGroupsRoutePage/CasinoGroupsRoutePage.vue'),
    prefetch: () => import('web/src/modules/casino/submodules/groups/prefetch/CasinoGroupsPagePrefetch'),
  };
}

export class CasinoGroupsModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      ...getBaseCasinoGroupsRoute({
        name: CasinoRouteName.CASINO_GROUPS,
        path: '/casino/groups',
      }),
    });

    router.addModalRoute({
      ...getBaseCasinoGroupsRoute({
        name: CasinoRouteName.CASINO_LIVE_GROUPS,
        path: '/live-casino/groups',
      }),
    });
  }
}
