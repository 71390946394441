import { RouteName } from '@leon-hub/routing-config-names';
import { CustomerRouteAccessRole } from '@leon-hub/routing-config';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

export default function createRestorePasswordRoutes(router: AppVueRouter): void {
  router.addModalRoute({
    name: RouteName.RESTORE_PASSWORD,
    path: '/login/restore',
    access: CustomerRouteAccessRole.ANONYMOUS,
    modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
    prefetch: () => import('web/src/modules/restore-password/prefetch/RestoreMainPagePrefetch'),
    component: () => import('web/src/modules/restore-password/components/RestorePasswordMainRoutePage/RestorePasswordMainRoutePage.vue'),
    topBar: () => import('web/src/modules/restore-password/components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue'),
  });

  router.addModalRoute({
    name: RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE,
    path: '/pwdreset/:uid?/:email?',
    access: CustomerRouteAccessRole.ANONYMOUS,
    modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
    prefetch: () => import('web/src/modules/restore-password/prefetch/RestorePasswordLastStepPagePrefetch'),
    component: () => import('web/src/modules/restore-password/components/RestorePasswordLastRoutePage/RestorePasswordLastRoutePage.vue'),
    topBar: () => import('web/src/modules/restore-password/components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue'),
  });

  router.addModalRoute({
    name: RouteName.UNTRUSTED_DEVICE,
    path: '/untrusted-device/:hashId',
    modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
    component: () => import('web/src/modules/restore-password/submodules/UntrustedDeviceRouteComponent/UntrustedDeviceRouteComponent.vue'),
    title: '{{$t(\'WEB2_PASSWORD_CHANGE\')}}',
  });

  router.addPrefetchRoute({
    name: RouteName.RESTORE_PASSWORD_APP,
    path: '/pwdresetapp/:applink',
    access: CustomerRouteAccessRole.ANONYMOUS,
    prefetch: () => import('web/src/modules/restore-password/prefetch/RestorePasswordAppPrefetch'),
  });
}
