import { RouteName } from '@leon-hub/routing-config-names';
import { CustomerRouteAccessRole } from '@leon-hub/routing-config';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export class PushNotificationsModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.PUSH_NOTIFICATIONS_BETS_ONBOARDING,
      path: '/push-notifications-bets-onboarding',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      access: CustomerRouteAccessRole.AUTHORIZED,
      // eslint-disable-next-line max-len
      component: () => import('web/src/modules/push-notifications/pages/PushNotificationsBetsOnboardingRoutePage/PushNotificationsBetsOnboardingRoutePage.vue'),
    });
  }
}
