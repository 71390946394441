import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import { useRouterStore } from 'web/src/modules/core/store';

import type { AppVueRouter } from '../types';

export default function performLogin(router: AppVueRouter): void {
  const { currentRoute, getAuthorizedRouteHistoryDelta } = useRouterStore();

  if (currentRoute?.meta.saveRouteOnLogin) {
    return;
  }

  if (window.history.state?.toRoute) {
    void router.replace(window.history.state.toRoute);
    return;
  }

  if (currentRoute?.meta.access === CustomerRouteAccessRole.ANONYMOUS) {
    const delta = getAuthorizedRouteHistoryDelta();
    if (delta !== 0) {
      router.go(delta);
    } else {
      void router.replace({
        name: RouteName.HOME,
      });
    }
  }
}
