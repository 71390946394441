/* eslint-disable max-len */
import { RouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';

export class ResponsibleGamblingModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    if (process.env.VUE_APP_FEATURE_DELETE_ACCOUNT_ENABLED) {
      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_DELETE_ACCOUNT,
        path: '/profile/settings/responsible-gambling/delete-account',
        component: () => import('./pages/ResponsibleGamblingDeleteAccountRoutePage/ResponsibleGamblingDeleteAccountRoutePage.vue'),
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING_DELETE_ACCOUNT_PAGE_TITLE\')}}',
      });
    }

    if (useSiteConfigStore().responsibleGamblingV2Enabled) {
      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_OPTIONS,
        path: '/profile/settings/responsible-gambling/gambling-options',
        component: () => import('./pages/ResponsibleGamblingOptionsRoutePage/ResponsibleGamblingOptionsRoutePage.vue'),
        topBar: () => import('./views/ResponsibleGamblingTopBarRouteComponent/ResponsibleGamblingTopBarRouteComponent.vue'),
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING_OPTIONS_PAGE_TITLE\')}}',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_TEST,
        path: '/profile/settings/responsible-gambling/test',
        component: () => import('./pages/ResponsibleGamblingTestRoutePage/ResponsibleGamblingTestRoutePage.vue'),
        title: '',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_LIMITS,
        path: '/profile/settings/responsible-gambling/limits',
        component: () => import('./pages/ResponsibleGamblingLimitsRoutePage/ResponsibleGamblingLimitsRoutePage.vue'),
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING_OPTIONS_PAGE_TITLE_LIMITS\')}}',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_INFO,
        path: '/profile/settings/responsible-gambling/info',
        component: () => import('./pages/ResponsibleGamblingInfoRoutePage/ResponsibleGamblingInfoRoutePage.vue'),
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING_OPTIONS_PAGE_TITLE_INFO\')}}',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_QUIZ,
        path: '/profile/settings/responsible-gambling/quiz',
        component: () => import('./pages/ResponsibleGamblingQuizRoutePage/ResponsibleGamblingQuizRoutePage.vue'),
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING_OPTIONS_PAGE_TITLE_QUIZ\')}}',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_TIME_IN_GAME,
        path: '/profile/settings/responsible-gambling/time-in-game',
        prefetch: () => import('./prefetch/ResponsibleGamblingTimeInGameRoutePagePrefetch'),
        component: () => import('./pages/ResponsibleGamblingTimeInGameRoutePage/ResponsibleGamblingTimeInGameRoutePage.vue'),
        topBar: () => import('./views/ResponsibleGamblingTimeInGameTopBarRoutePage/ResponsibleGamblingTimeInGameTopBarRoutePage.vue'),
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_TIME_IN_GAME_INFO,
        path: '/profile/settings/responsible-gambling/time-in-game-info',
        prefetch: () => import('./prefetch/ResponsibleGamblingTimeInGameRoutePagePrefetch'),
        component: () => import('./pages/ResponsibleGamblingTimeInGameRoutePage/ResponsibleGamblingTimeInGameInfoRoutePage.vue'),
        topBar: () => import('./views/ResponsibleGamblingTimeInGameTopBarRoutePage/ResponsibleGamblingTimeInGameTopBarRoutePage.vue'),
        topBarProps: {
          info: true,
        },
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_SESSION_LIMIT,
        path: '/profile/settings/responsible-gambling/session-limit',
        component: () => import('./pages/ResponsibleGamblingSessionLimitRoutePage/ResponsibleGamblingSessionLimitRoutePage.vue'),
        title: '',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT,
        path: '/profile/settings/responsible-gambling/deposit-limit',
        component: () => import('./pages/ResponsibleGamblingDepositLimitRoutePage/ResponsibleGamblingDepositLimitRoutePage.vue'),
        topBar: () => import('./components/ResponsibleGamblingTopBar/ResponsibleGamblingTopBar.vue'),
        topBarProps: {
          isDepositLimit: true,
          title: '{{$t(\'WEB2_RG_DEPOSIT_TOP_BAR_TITLE\')}}',
        },
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT_INFO,
        path: '/profile/settings/responsible-gambling-deposit-limit-info',
        component: () => import('./pages/ResponsibleGamblingDepositLimitInfoRoutePage/ResponsibleGamblingDepositLimitInfoRoutePage.vue'),
        title: '',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_BET_LIMIT,
        path: '/profile/settings/responsible-gambling/bet-limit',
        component: () => import('./pages/ResponsibleGamblingBetLimitRoutePage/ResponsibleGamblingBetLimitRoutePage.vue'),
        topBar: () => import('./components/ResponsibleGamblingTopBar/ResponsibleGamblingTopBar.vue'),
        topBarProps: {
          isBetLimit: true,
          title: '{{$t(\'WEB2_RG_BET_TOP_BAR_TITLE\')}}',
        },
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_BET_LIMIT_INFO,
        path: '/profile/settings/responsible-gambling-bet-limit-info',
        component: () => import('./pages/ResponsibleGamblingBetLimitInfoRoutePage/ResponsibleGamblingBetLimitInfoRoutePage.vue'),

        title: '',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_BREAK,
        path: '/profile/settings/responsible-gambling/break',
        component: () => import('./pages/ResponsibleGamblingBreakRoutePage/ResponsibleGamblingBreakRoutePage.vue'),
        title: '',
      });

      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_EXCLUSION,
        path: '/profile/settings/responsible-gambling/self-exclusion',
        component: () => import('./pages/ResponsibleGamblingExclusionRoutePage/ResponsibleGamblingExclusionRoutePage.vue'),
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMBLING_EXCLUSION_TITLE\')}}',
        topBar: () => import('./views/ResponsibleGamblingExclusionTopBarRoutePage/ResponsibleGamblingExclusionTopBarRoutePage.vue'),
      });
      router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_EXCLUSION_INFO,
        path: '/profile/settings/responsible-gambling/self-exclusion-info',
        title: '',
        component: () => import('./pages/ResponsibleGamblingExclusionRoutePage/ResponsibleGamblingExclusionInfoRoutePage.vue'),
        topBar: () => import('./views/ResponsibleGamblingExclusionTopBarRoutePage/ResponsibleGamblingExclusionTopBarRoutePage.vue'),
        topBarProps: {
          info: true,
        },
      });
    }
  }
}
