import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';
import type { ScrollToElementOptions } from '@leon-hub/scrollbar-types';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useForceSyncStateAll } from 'web/src/modules/core/submodules/sync-state';
import initThemeAppInterface from 'web/src/plugins/AppPlugins/initThemeAppInterface';

import initAppRouterInterface from './initAppRouterInterface';
import initMetaInfoInterface from './initMetaInfoInterface';
import initErrorsInterface from './initErrorsInterface';
import initCustomerAppInterface from './initCustomerAppInterface';

export default function initPublicAppInterface(router: AppVueRouter): void {
  if (process.env.VUE_APP_RENDERING_SSR || process.env.VUE_APP_PRERENDER) {
    return;
  }

  // public block
  window.mainApp = window.mainApp || {};

  if (useIsDevIP().isDevIP.value) {
    const bus = useEventsBus();

    // tech block
    window.mainApp.isDevIP = true;
    initMetaInfoInterface(router);
    initCustomerAppInterface();
    initErrorsInterface(bus);
    initAppRouterInterface(router);
    initThemeAppInterface();
    const { forceSyncStateAll } = useForceSyncStateAll();

    window.mainApp.syncState = forceSyncStateAll;
    window.mainApp.scrollbarScrollTo = function scrollbarScrollTo(
      scrollbar: string,
      element: HTMLElement | null,
      options?: ScrollToElementOptions,
    ) {
      bus.emit(BusEvent.QA_SCROLLBAR_SCROLL_TO, { scrollbar, el: element, options });
    };
    Object.defineProperty(window.mainApp, 'routeName', {
      set() {
        throw new Error('Cannot redefine readonly property "routeName". Use configuration for change app navigation');
      },
      get() {
        return Object.freeze({
          ...RouteName,
          ...(process.env.VUE_APP_FEATURE_CASINO_ENABLED ? CasinoRouteName : RouteName),
        });
      },
    });
  }
}
