import { RouteName } from '@leon-hub/routing-config-names';
import { CustomerRouteAccessRole } from '@leon-hub/routing-config';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

export class RegistrationCompletionModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.REGISTRATION_COMPLETION,
      path: '/registration/completion/:customerAction/:paymentSystemId?',
      access: CustomerRouteAccessRole.AUTHORIZED,
      prefetch: () => import('./prefetch/RegistrationCompletionPrefetch'),
      component: () => import('web/src/modules/registration-completion/pages/RegistrationCompletionRoutePage/RegistrationCompletionRoutePage.vue'),
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      topBar: () => import('web/src/modules/registration-completion/views/RegistrationCompletionTopBarRouteComponent/RegistrationCompletionTopBarRouteComponent.vue'),
    });
  }
}
