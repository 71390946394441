<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

import { SvgSpriteAdditionalDefs } from '@components/v-icon';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import CordovaDialogsController from 'web/src/modules/cordova/components/CordovaDialogsController/CordovaDialogsController.vue';
import DiagnosticsController from 'web/src/modules/core/components/DiagnosticsController/DiagnosticsController.vue';
import { getAsyncComponent } from 'web/src/modules/core/utils';

import { useMainApp } from './composables';

const PostponedAppController = defineAsyncComponent(
  () => import('web/src/modules/core/components/PostponedAppController/PostponedAppController.vue'),
);
const AuthorizedAppController = defineAsyncComponent(
  () => import('web/src/modules/core/components/AuthorizedAppController/AuthorizedAppController.vue'),
);

const GuestAppController = getAsyncComponent(
  () => import('web/src/modules/core/components/GuestAppController/GuestAppController.vue'),
);

const TheMetaInfo = defineAsyncComponent(() => import('web/src/modules/seo/components/TheMetaInfo.vue'));
const TheModal = defineAsyncComponent(() => import('web/src/modules/dialogs/views/TheModal/TheModal.vue'));

const { isLoggedIn } = useIsLoggedIn();
const {
  errorComponent,
  mainComponent,
  isPostponedControllerVisible,
  isAppInit,
  onAppControllerError,
  onAppControllerLoaded,
} = useMainApp();
</script>

<template>
  <SvgSpriteAdditionalDefs />

  <template v-if="errorComponent">
    <component :is="errorComponent" />
  </template>
  <template v-else>
    <NuxtLayout v-if="null" />
    <component
      :is="mainComponent"
      v-else
    />
    <TheModal />
    <PostponedAppController
      v-if="isPostponedControllerVisible"
    />
    <AuthorizedAppController
      v-if="isLoggedIn"
      :is-app-init="isAppInit"
      @load-error="onAppControllerError"
      @loaded="onAppControllerLoaded"
    />
    <GuestAppController
      v-else
      :is-app-init="isAppInit"
      @load-error="onAppControllerError"
      @loaded="onAppControllerLoaded"
    />
  </template>

  <TheMetaInfo />
  <DiagnosticsController
    v-if="null"
  />
  <CordovaDialogsController
    v-if="null"
  />
</template>
