<script lang="ts" setup>
import { BaseRouteName } from '@leon-hub/routing-config-names';
import { Tag } from '@leon-hub/tags';
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';
import {
  ButtonHeight,
  ButtonKind,
} from '@leon-hub/module-buttons';

import BalanceModal from 'web/src/modules/profile/components/BalanceModal/BalanceModal.vue';
import VBalance from 'web/src/components/Balance/VBalance/VBalance.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import HeaderAvatar from 'web/src/modules/core/components/HeaderAvatar/HeaderAvatar.vue';
import HeaderNotificationsButton from 'web/src/modules/core/components/HeaderNotificationsButton/HeaderNotificationsButton.vue';
import { getDefaultAvatarImage } from 'web/src/components/Avatar/utils';
import { useCustomerFinalBalance } from 'web/src/modules/customer/composables';
import HeaderBonusButton from 'web/src/modules/core/components/HeaderBonusButton/HeaderBonusButton.vue';

import { useHeaderProfile } from './composables';

const {
  bonusValue,
  isLowBalance,
  isAddBalanceButtonAsCurrency,
  isHiddenBalance,
  isGamblePrevented,
  isBalanceModalShown,
  isBalanceOpened,
  showBonusButton,
  closeBalanceModal,
  handleTopUp,
  showBalanceModal,
  onBonusClick,
} = useHeaderProfile();

const {
  totalBalance,
  isSumOfBalancesEnabled,
} = useCustomerFinalBalance(!null);
</script>

<template>
  <ul v-auto-id="'HeaderProfile'" :class="$style['header-profile']">
    <li
      v-if="showBonusButton"
    >
      <HeaderBonusButton @bonus-click="onBonusClick" />
    </li>
    <li
      v-if="!null"
    >
      <HeaderNotificationsButton />
    </li>
    <VBalance
      :tag="Tag.LI"
      :class="[
        $style['header-profile__item'] ,
        $style['header-profile__item--balance']
      ]"
      :value="totalBalance"
      :value-bonus="bonusValue"
      :is-low-balance="isLowBalance"
      :is-add-button-as-currency="isAddBalanceButtonAsCurrency"
      :is-balance-hidden="isHiddenBalance"
      :is-sum-of-balances-enabled="isSumOfBalancesEnabled"
      is-header
      @top-up="handleTopUp"
      @click-button-gift="showBalanceModal"
    />
    <li v-if="null">
      <HeaderNotificationsButton />
    </li>
    <li v-if="null">
      <HeaderAvatar
        :image="null ? {
          src: getDefaultAvatarImage(),
        } : undefined"
      />
    </li>
    <li
      v-if="'1' && !null"
      :class="$style['header-profile__item']"
    >
      <router-link :to="{ name: BaseRouteName.PROFILE }">
        <VButton
          :icon-name="IconName.PERSON_FILL"
          :icon-size="IconSize.SIZE_24"
          :height="ButtonHeight.SMALL"
          :kind="'1' ? ButtonKind.HEADER_DARK_VERSION : ButtonKind.HEADER"
          rounded
          :class="{
            [$style['header-profile__avatar']]: true,
            [$style['header-profile__avatar--identification']]: isGamblePrevented,
          }"
        />
        <div
          v-if="isGamblePrevented"
          :class="[
            $style['header-profile__item-badge'],
            $style['header-profile__item-badge--danger'],
          ]"
        />
      </router-link>
    </li>
    <BalanceModal
      v-if="isBalanceModalShown"
      :is-open="isBalanceOpened"
      @close="closeBalanceModal"
    />
  </ul>
</template>

<style lang="scss" module>
.header-profile {
  display: flex;
  gap: $headerProfileSizeBetweenButtonsPhone;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  @include screen-desktop-small-min {
    gap: $headerProfileSizeBetweenButtonsDesktop;
  }

  &__item {
    position: relative;
    display: flex;

    &--balance {
      background-color: $headerProfileBalanceBackgroundColor;

      &:hover {
        background-color: $headerProfileBalanceBackgroundColorHover;
      }
    }
  }

  &__item-badge {
    @include z-index(base);

    position: absolute;
    top: 8px;
    right: 8px;
    width: 5px;
    height: 5px;
    background-color: var(--AlertDefault);
    border-radius: 50%;

    &--danger {
      background-color: var(--ErrorDefault);
    }
  }

  @include is-app-layout-desktop {
    padding-left: 6px;
  }
}
</style>
