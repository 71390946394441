/* eslint-disable max-len */
import { RouteName } from '@leon-hub/routing-config-names';
import { CustomerRouteAccessRole } from '@leon-hub/routing-config';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export class ReferralProgramModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.REFERRAL_REGISTRATION,
      path: '/ref/:code',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP
        ? ModalDesktopPreset.SmallDesktopModal
        : undefined,
      access: CustomerRouteAccessRole.ANONYMOUS,
      prefetch: () => import('web/src/modules/referral-program/prefetch/ReferralProgramRegistrationPrefetch'),
      topBar: () => import('web/src/modules/referral-program/components/ReferralProgramRegistrationTopBar/ReferralProgramRegistrationTopBar.vue'),
      component: () => import('web/src/modules/referral-program/views/ReferralProgramRegistration/ReferralProgramRegistration.vue'),
    });

    router.addProfileRoute({
      name: RouteName.REFERRAL_PROGRAM,
      path: '/referral-program',
      prefetch: () => import('web/src/modules/referral-program/prefetch/ReferralProgramPrefetch'),
      component: () => import('web/src/modules/referral-program/pages/ReferralProgramRoutePage/ReferralProgramRoutePage.vue'),
      topBar: () => import('web/src/modules/referral-program/layouts/ReferralProgramTopBarRouteComponent.vue'),
      title: '{{$t(\'WEB2_REFFERAL_PROGRAM_INVITE\')}}',
    });

    router.addProfileRoute({
      name: RouteName.REFERRAL_PROGRAM_INFO,
      path: '/referral-program-info',
      prefetch: () => import('web/src/modules/referral-program/prefetch/ReferralProgramPrefetch'),
      component: () => import('web/src/modules/referral-program/pages/ReferralProgramInfoPage/ReferralProgramInfoPage.vue'),
      title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? '{{$t(\'WEB2_REFERRAL_PROGRAM_TAB_CONDITIONS\')}}' : '{{$t(\'REFERRAL_PROGRAM_INFO_TITLE\')}}',
    });
  }
}
