import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';

import { SearchTab } from 'web/src/modules/search/enums';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

const modalPreset = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
  ? ModalDesktopPreset.SmallDesktopModal
  : ModalDesktopPreset.SearchDesktopModal;

export class SearchModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.SEARCH,
      path: '/search',
      component: () => import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue'),
      title: '{{$t(\'WEB2_SEARCH_TITLE\')}}',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? modalPreset : undefined,
    });

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      router.addModalRoute({
        name: CasinoRouteName.CASINO_SEARCH,
        path: '/casino/search',
        component: () => import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue'),
        props: {
          defaultTabIndex: SearchTab.SLOTS,
        },
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? modalPreset : undefined,
        title: '{{$t(\'WEB2_SEARCH_TITLE\')}}',
      });
    }
  }
}
