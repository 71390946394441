/* eslint-disable no-param-reassign */
import type { App } from 'vue';

import {
  AutomationPlugin,
  disableAutomationPlugin,
  enableAutomationPlugin,
  isAutomationPluginEnabled,
} from '@leon-hub/directive-automation';

import { useRootStore } from 'web/src/modules/core/store';

import configureDevelopmentPlugins from './configureDevelopmentPlugins';
import configureErrorHandler from './configureErrorHandler';

export default function configureVueApp(app: App): App {
  const automationPluginEnabledStatus = isAutomationPluginEnabled(useRootStore().isDevIP);
  app.use(AutomationPlugin, {
    elemAttrName: 'data-auto-id',
    isEnabled: automationPluginEnabledStatus,
  });
  if (automationPluginEnabledStatus) {
    disableAutomationPlugin();
    enableAutomationPlugin();
  } else {
    disableAutomationPlugin();
  }

  app = configureErrorHandler(app);

  if (process.env.NODE_ENV === 'development') {
    app = configureDevelopmentPlugins(app);
  }

  return app;
}
