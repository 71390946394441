import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';

import type { CancelWithdrawalRouteInfo } from '@leon-hub/api-sdk';
import {
  assert,
  isString,
} from '@leon-hub/guards';
import { IconName } from '@leon-hub/icons';
import { ModalWidth } from '@leon-hub/module-modal';
import { Timer } from '@leon-hub/utils';

import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
import { useRouterStore } from 'web/src/modules/core/store';
import { useCustomerFinalBalance } from 'web/src/modules/customer/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useWithdrawalCancel } from 'web/src/modules/payments/composables/useWithdrawalCancel';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';

type WithdrawalCancelPopup = Pick<CancelWithdrawalRouteInfo, 'routeName' | 'btrSubject' | 'btrMessage' | 'btrButton' | 'onlyZeroBalanceShow'>;

export const useWithdrawalCancelPopupsStore = defineStore('withdrawal-cancel-popups', () => {
  const paymentsStore = usePaymentsStore();
  const routerStore = useRouterStore();
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();
  const {
    paymentsWithdrawalCancelPopupRoutes,
    paymentsWithdrawalCancelPopupDelay,
    paymentsWithdrawalCancelPopupInterval,
    isPaymentsWithdrawalCancelButtonInSuccessModalEnabled: isWithdrawalCancelEnabled,
    zeroBalanceLimit,
  } = usePaymentsConfig();
  const {
    totalBalance,
  } = useCustomerFinalBalance();
  const { handleCancelWithdrawal, sendAnalyticsWhenPopupSetVisible } = useWithdrawalCancel();

  const currentRouteName = toRef(() => routerStore.currentRouteName);
  const requestedCount = toRef(() => paymentsStore.requestedCount ?? 0);
  const minPaymentCreatedAt = toRef(() => paymentsStore.minPaymentCreatedAt ?? 0);

  let timeout = 0;

  const withdrawalCancelPopup = ref<WithdrawalCancelPopup>();
  const withdrawalCancelPopupTitle = computed(() => {
    const btrKey = withdrawalCancelPopup.value?.btrSubject;
    assert(isString(btrKey));
    return $translate(btrKey).value;
  });
  const withdrawalCancelPopupMessage = computed(() => {
    const btrKey = withdrawalCancelPopup.value?.btrMessage;
    assert(isString(btrKey));
    return $translate(btrKey).value;
  });
  const withdrawalCancelPopupButton = computed(() => {
    const btrKey = withdrawalCancelPopup.value?.btrButton;
    assert(isString(btrKey));
    return $translate(btrKey).value;
  });

  const isWithdrawalCreatedAfterDelay = computed(() => {
    return Date.now() - minPaymentCreatedAt.value > (paymentsWithdrawalCancelPopupDelay.value * 1000);
  });

  function startShowPopup(): void {
    if (!requestedCount.value || !withdrawalCancelPopup.value)
      return;

    if (isWithdrawalCreatedAfterDelay.value) {
      showWithdrawalDefaultPopup();
      startTimer();
    }
  }

  function showWithdrawalDefaultPopup(): void {
    sendAnalyticsWhenPopupSetVisible();

    const { subscribe } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: withdrawalCancelPopupTitle.value,
        confirmMessage: withdrawalCancelPopupMessage.value,
        width: ModalWidth.SMALL,
        iconName: IconName.CLOCK,
        iconKind: JumbotronIconKind.SUCCESS,
        isCentered: false,
        buttons: [
          {
            label: withdrawalCancelPopupButton.value,
            action: DialogAction.CONFIRM,
          },
          {
            label: $translate('WEB2_BACK_LABEL').value,
          },
        ],
      },
    });

    subscribe({
      [DialogAction.CONFIRM]: () => handleCancelWithdrawal(),
    });
  }

  function startTimer(): void {
    clearTimer();
    timeout = Timer.setInterval(() => {
      showWithdrawalDefaultPopup();
    }, paymentsWithdrawalCancelPopupInterval.value * 1000);
  }

  function clearTimer(): void {
    if (timeout) {
      clearInterval(timeout);
      timeout = 0;
    }
  }

  watch(currentRouteName, (newRouteName) => {
    const currentPopup = paymentsWithdrawalCancelPopupRoutes.value.find(
      (route) => newRouteName === route.routeName,
    );

    if (currentPopup) {
      withdrawalCancelPopup.value = currentPopup;
      const limit = zeroBalanceLimit?.value ?? 0;

      if (currentPopup.onlyZeroBalanceShow && totalBalance.value < limit) {
        showWithdrawalDefaultPopup();
      } else {
        startShowPopup();
      }
    } else {
      withdrawalCancelPopup.value = undefined;
      clearTimer();
    }
  }, { immediate: true });

  watch(totalBalance, (newBalance) => {
    const limit = zeroBalanceLimit?.value ?? 0;
    if (newBalance < limit && withdrawalCancelPopup.value?.onlyZeroBalanceShow) {
      showWithdrawalDefaultPopup();
    }
  }, { immediate: true });

  return {
    startShowPopup,
    isWithdrawalCancelEnabled,
  };
});
