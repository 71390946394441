<script lang="ts" setup>
import { ref } from 'vue';

import type { VBrandProps } from './types';
import { useVBrand } from './composables';
import { prepareUseLogoProps } from './composables/utils';

interface VBrandEmits {
  (e: 'click', event: MouseEvent): void;
}

const props = withDefaults(defineProps<VBrandProps>(), {
  alt: '',
});
const emit = defineEmits<VBrandEmits>();

const {
  logoUrl,
} = useVBrand({
  ...prepareUseLogoProps(props),
  customBrand: ref('esport'),
});

function onClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <img v-auto-id="'VESportBrand'"
    :src="logoUrl"
    :alt="alt"
    :class="{
      [$style['brand']]: true,
    }"
    @click="onClick"
  >
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .brand {
    @if $isDesktop {
      @include screen-desktop-small-min {
        width: $brandLogoEsportWidthFullDesktopSmall;
        height: $brandLogoEsportHeightFullDesktopSmall;
      }

      @include screen-desktop-large-min {
        width: $brandLogoEsportWidthFullDesktopLarge;
        height: $brandLogoEsportHeightFullDesktopLarge;
      }
    } @else {
      width: $brandLogoEsportWidthPhone;
      height: $brandLogoEsportHeightPhone;
    }
  }
}
</style>
